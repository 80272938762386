$(document).on("turbolinks:load", function () {
  $(".js-btn-collapse-arrow").on("click", function() {
    var collapse = $(this).attr("aria-controls");
    var paramName = collapse + "_expanded";
    var expanded = $(this).attr("aria-expanded");

    $(".js-sort-link").each(function() {
      var href = $(this).attr("href");
      var url = window.location.origin + href;

      var urlObject = new URL(url);
      var pathname = urlObject.pathname
      var search = urlObject.search;

      var urlSeachParams = new URLSearchParams(search);
      urlSeachParams.set(paramName, expanded);
      var newSearch = urlSeachParams.toString();
      var newHref = pathname + "?" + newSearch;
      $(this).attr("href", newHref);
    });
  })
});
