$(document).on("turbolinks:load", function () {
  $("#js-checkbox-consumer-agree-policy").on("change", function() {
    let checkbox = $("#js-checkbox-consumer-agree-policy");
    let btn = $(".js-btn-consumer-sign-up");

    if (checkbox.is(":checked")) {
      btn.removeAttr("disabled");
    } else {
      btn.attr("disabled", "disabled");
    }
  });
});
