// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import "channels"
import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import moment from "moment";
import { I18n } from "i18n-js";
import translations from "/public/i18n_js/ja/translations.json";

const i18n = new I18n(translations);
i18n.defaultLocale = "ja";
i18n.locale = "ja";

Chart.register(annotationPlugin);

global.$ = require("jquery");
global.toastr = require("toastr");
global.Chart = Chart;
global.moment = moment;
global.i18n = i18n;

require("datetimepicker");

$.datetimepicker.setDateFormatter("moment");
$.datetimepicker.setLocale("ja");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "packs/consumer/form"
import "packs/consumer/modal"
import "packs/consumer/sign_up"
import "packs/consumer/profile"
import "packs/consumer/datetimepicker"

import "packs/alliance_staff/form"

import "packs/admin/users/modal_confirm_delete_user"
import "packs/admin/user/shared/collapse/arrow"