$(document).on("turbolinks:load", function () {
  $("html").css("overflow", "");

  const show = function(id) {
    $(id).addClass("show");
    $("html").css("overflow", "hidden");
  };

  const hide = function(id) {
    $(id).removeClass("show");
    $("html").css("overflow", "");
  };

  const modalTargetArr = [];
  $('.s-modal').each(function() {
    const modalTarget = $(this).attr("id");
    modalTargetArr.push(`#${modalTarget}`);
  });
  $('*[data-modal="s-modal"]').each(function() {
    const modalTarget = $(this).attr("data-modal-target");
    if (!modalTargetArr.includes(modalTarget))
      modalTargetArr.push(modalTarget);
  });

  // Unique modal target
  [...new Set(modalTargetArr)].forEach(target => {
    $(`*[data-modal-target="${target}"]`).on("click", function() {
      show(target);
    });
    $(target).find('.s-modal-close').on("click", function() {
      hide(target);
    });
    $(target).find('.s-modal__backdrop').on("click", function() {
      hide(target);
    });
  });
});
