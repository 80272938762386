$(document).on("turbolinks:load", function () {
  $("#datetimepicker").datetimepicker({
    format:'YYYY-MM-DD H:mm',
    formatTime:'H:mm',
    formatDate:'YYYY-MM-DD',
    step: 5
  });

  $("#datepicker").datetimepicker({
    format:'YYYY-MM-DD',
    formatDate:'YYYY-MM-DD',
    timepicker: false
  });

  $("#logChartMonthpicker").datetimepicker({
    format: 'YYYY-MM',
    viewMode: 'years',
    onSelectDate: function () {
      const datepicker = $("#logChartMonthpicker")[0];
      const selectedDate = datepicker.value
      const href = new URL(window.location.href)
      href.searchParams.set('month', selectedDate);
      window.location.href = href.toString()
    }
  })
});
