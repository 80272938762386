$(document).on("turbolinks:load", function () {
  $("#js-checkbox-consumer-profile-others-disease").on("change", function() {
    let checkbox = $("#js-checkbox-consumer-profile-others-disease");
    let input = $(".js-input-consumer-profile-others-disease");

    if (checkbox.is(":checked")) {
      input.removeClass("d-none");
    } else {
      input.addClass("d-none");
    }
  });

  const MARGIN_TOP_CLASS = { 1: "mt-48", 2: "mt-182", 3: "mt-60" };
  const handleActiveStep = function (stepEl) {
    $(".edit-profile-page__bar-item").removeClass("active");
    $(".edit-profile-page__form--step").addClass("d-none");

    $(stepEl).addClass("active");
    const stepTarget = Number($(stepEl).attr("data-step"));
    $(`#formStep${stepTarget}`).removeClass("d-none");
    Object.values(MARGIN_TOP_CLASS).forEach(marginTopClass => $("#formButton").removeClass(marginTopClass));
    $("#formButton").addClass(MARGIN_TOP_CLASS[stepTarget]);
  };

  // Collect & register event
  const activeSteps = [];
  $(".edit-profile-page__bar-item").each(function() {
    // collect active steps
    if ($(this).hasClass("active")) activeSteps.push(this);

    // register event
    $(this).on("click", function() {
      handleActiveStep(this);
    });
  });

  // Init active step
  activeSteps.forEach(stepEl => handleActiveStep(stepEl));
});
