$(document).on("turbolinks:load", function () {
  $(".js-btn-delete-user").on("click", function(){
    var userId = $(this).data("user-id");
    $('#js-btn-confirm-delete-user').attr("href", "/admin/users/" + userId);
    $("#js-modal-confirm-delete-user").show();
  });

  $("#js-btn-cancel-delete-user").on("click", function(){
    $("#js-modal-confirm-delete-user").hide();
  });
});
