$(document).on("turbolinks:load", function () {
  // Handle show, hide event for all password controls
  const handlePasswordControl = function(groupId, status) {
    $(`${groupId} .s-form-password-show`).toggleClass("d-none");
    $(`${groupId} .s-form-password-hide`).toggleClass("d-none");
    $(`${groupId} input.s-form-control`).attr("type", status === "show" ? "text" : "password")
  }

  const PWD_ID_GROUP = ["#js-password-sign-in", "#js-password-sign-up", "#js-password-confirm-sign-up", "#js-change-password", "#js-change-password-confirm"];
  PWD_ID_GROUP.forEach(id => {
    $(`${id} .s-form-password-show`).on("click", function () {
      handlePasswordControl(id, "hide");
    });
    $(`${id} .s-form-password-hide`).on("click", function () {
      handlePasswordControl(id, "show");
    });
  });
});
